/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Esta es una introducción a Festival, el programa para linux que puede leer todo lo que tu le queras poner."), "\n", React.createElement(_components.p, null, "El programa me lo he instalado en un momento con el Synaptic, luego como estaba la voz en inglés me e bajado el paquete festvox_ellpc11k.tar.gz de ", React.createElement(_components.a, {
    href: "http://festvox.org/packed/festival/1.95/"
  }, "esta dirección"), ", después e descomprimido los archivos en las carpetas que ponia en el paquete, acordaos que para todo esto es necesario utilizar privilegios de root."), "\n", React.createElement(_components.p, null, "Ahora se puede probar el programa escribiendo en la consola:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "festival —language spanish")), "\n", React.createElement(_components.p, null, "Nos saldrá la consola de festival que es así:"), "\n", React.createElement(_components.p, null, "festival>"), "\n", React.createElement(_components.p, null, "Ahora podemos escribir lo que queramos que suene por su propia voz."), "\n", React.createElement(_components.p, null, "festival> (SayText “Hola, que tal”)"), "\n", React.createElement(_components.p, null, "Bueno esta es la introducción a este gran programa, cuando pueda continuare explicando nuevos."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
